
import { defineComponent, computed, onMounted, ref } from 'vue'
import AddUser from '@/components/core/user/AddUser.vue'
import CardUser from '@/components/core/user/CardUser.vue'
import { User, USER_ROLES } from '@/model/User'
import { Toaster } from '@/common/Toaster'
import { StoreGettersAuth, storeAuth } from '@/store/auth'
import { storeUser, StoreActionsUser, StoreGettersUser } from '@/store/user'

export default defineComponent({
  name: `ViewUsers`,
  components: { AddUser, CardUser },
  props: {},
  emits: [],
  setup() {
    const loading = ref(false)
    const addUserEl: any = ref(null)
    const loadingAddUser = ref(false)

    onMounted(async () => {
      if (!users.value.length) {
        loading.value = true
      }
      await storeUser.dispatch(StoreActionsUser.GET_ALL)
      loading.value = false
    })

    const canAddUser = computed((): boolean => {
      return storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN
    })

    const users = computed((): User[] => {
      return storeUser.getters[StoreGettersUser.USERS]
    })

    const addUser = async (user: User) => {
      loadingAddUser.value = true
      const res = await storeUser.dispatch(StoreActionsUser.ADD, user)
      loadingAddUser.value = false
      Toaster.toast(res)
      if (res.success) {
        addUserEl.value?.reset()
      }
    }

    return {
      canAddUser,
      users,
      addUser,
      loadingAddUser,
      loading,
      addUserEl,
    }
  },
})
